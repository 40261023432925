import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../../Components/Helpers/Helpers'
import { FormCustom, Input } from '../../Components/Form/Form'
import { toast } from 'react-toastify'

const TextForm = ({ idText, fullImage }) => {
    const [ready, setReady] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [rowImage, setRowImagen] = useState({
        p_id_text: '',
        p_name_text: '',
        p_file_image_text: []
    })

    useEffect(() => {
        if (!ready) {
            Controllers.text.get_text_id({ p_id_text: idText }).then(res => {
                setRowImagen({
                    p_id_text: res.data.id_text,
                    p_name_text: res.data.name_text,
                    p_file_image_text: res.data.file_image_text.map(f => {
                        let imageOriginal = Helpers.config.resourcesUrl({ url: f + '?' + Date.now()})
                        return {
                            value: '',
                            file: imageOriginal,
                            original: imageOriginal,
                            render: imageOriginal,
                            filePath: f
                        }
                    })
                })
                setReady(true)
            })
        }
    }, [ready, idText])

    const onSubmit = ({ data }) => {
        setLoadSubmit(true)
        Controllers.text.text_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setReady(false)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        let idx = 0
        for (const key in rowImage) {
            if (key === 'p_file_image_text') {
                rowImage.p_file_image_text.forEach(f => {
                    if (f.file?.files) {
                        formData.append('p_file_image_text_' + idx, f.file.files[0])
                    } else {
                        formData.append('p_file_image_text_' + idx, f.filePath)
                    }
                    idx++
                })
                continue
            }
            formData.append(key, rowImage[key])
        }
        formData.append('p_number_images', idx)

        return formData
    }, [rowImage])

    const textAdditional = useCallback(() => {
        let text = ''

        switch (parseInt(idText)) {
            case 3:
                text = <small><i>(1200 x 350)</i></small>
                break;
            case 4:
                text = <small><i>(1200 x 350)</i></small>
                break;
        
            default:
                break;
        }

        return text
    }, [idText])

    return <>
        <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between">
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={1}>
                    <span>{rowImage.p_name_text} {textAdditional()}</span>
                    {fullImage ? <i className="fa fa-plus cur-pointer text-primary mr-2" onClick={_ => setRowImagen({
                        ...rowImage,
                        p_file_image_text: [
                            ...rowImage.p_file_image_text,
                            {
                                value: '',
                                file: '',
                                original: '',
                                render: '',
                                filePath: '',
                                required: 1
                            }
                        ]
                    })}></i> : ''}
                </ReactPlaceholder>
            </Card.Header>
            <Card.Body style={{
            }}>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom dataSubmit={dataSubmit()} loadSubmit={loadSubmit} onSubmit={({ data }) => {
                        onSubmit({ data })
                    }}>
                        {React.Children.toArray(rowImage.p_file_image_text.map((f, i) => {
                            return <>
                                <div className="col-12 d-flex justify-content-between">
                                    <img src={f.render} className="img-fluid mb-2" style={{
                                        maxWidth: 300
                                    }} />
                                    {fullImage && i !== 0 ? <i className="fa fa-trash align-self-center cur-pointer" onClick={_ => {
                                        setRowImagen({
                                            ...rowImage,
                                            p_file_image_text: rowImage.p_file_image_text.filter((ff, j) => {
                                                return parseInt(i) !== parseInt(j)
                                            })
                                        })
                                    }}></i> : ''}
                                </div>
                                <Input
                                    classNameParent="col-12"
                                    type="file"
                                    text="Imagen"
                                    invalid="Seleccionar Imagen"
                                    accept="image/*"
                                    required={parseInt(f.required) === 1}
                                    onChange={e => {
                                        if (e.currentTarget.files.length > 0) {
                                            let fakePath = e.currentTarget.value
                                            let files = e.currentTarget
                                            let selectedFile = files.files[0]
                                            let reader = new FileReader()

                                            /* reader.onload = event => {
                                                setRowImagen({ ...rowImage, p_file_image_text: rowImage.p_file_image_text.map((ff, j) => {
                                                    if (i === j) {
                                                        ff.file = files,
                                                        ff.value = fakePath
                                                        ff.render = event.target.result
                                                    }

                                                    return ff
                                                }) })
                                            }
                                            reader.readAsDataURL(selectedFile) */
                                        } else {
                                            /* setRowImagen({ ...rowImage, p_file_image_text: rowImage.p_file_image_text.map((ff, j) => {
                                                if (i === j) {
                                                    ff.file = ff.original,
                                                    ff.value = ''
                                                    ff.render = ff.original
                                                }

                                                return ff
                                            }) }) */
                                        }
                                    }}
                                />
                                <div className="col-12">
                                    <hr />
                                </div>
                            </>
                        }))}
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

TextForm.defaultProps = {
    fullImage: false
}

export default TextForm