import React, { useState, useCallback, useMemo } from 'react'
import { Input, CheckBox } from '../../Form/Form'
import Button from 'react-bootstrap/Button'
import Box from './Styled/Box'
import Controllers from '../../../Api/Controllers'
import Helpers from '../../Helpers/Helpers'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'

const Login = () => {

  const { type } = useParams()

  const isLoginCustomers = type === 'clientes'

  const [session, setSession] = useState({
    username: Helpers.config.isDeveloperWeb() ? (isLoginCustomers ? '1' : 'administrador') : '',
    password: Helpers.config.isDeveloperWeb() ? '1234' : '',
  })

  const dataSubmit = useCallback(() => {
    return { 
      p_username: session.username,
      p_password: session.password,
      p_type: isLoginCustomers ? 1 : 2
    }
  }, [session])

  const handleSubmit = e => {
    e.preventDefault()
    let $this = e.target

    if (!$this.checkValidity()) {
      $this.classList.add('was-validated')
    } else {
      $this.classList.remove('was-validated')

      let button = $this.querySelector('button');
      let buttonHTML = button.innerHTML;
      button.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
      
      Controllers.users.get_login(dataSubmit()).then(res => {
        Helpers.toast.construct({ ...res, toast });
        if (res.response === 'success') {
          Helpers.localStorage.setSession({
            jwt: res.data.jwt
          });
          window.location.href = Helpers.config.convertUrl({ url: '/' });
        } else {
          button.innerHTML = buttonHTML;
        }
      }).catch(req => {
        Helpers.promise.catch({req, toast });
        button.innerHTML = buttonHTML;
      });
    }
  }

  const imageBackground = useMemo(() => {
    return Helpers.config.resourcesUrl({ url: type === 'clientes' ? '/images/dashboard/background-auth-customers.png?' + Date.now() : '/images/dashboard/background-auth-workers.png?' + Date.now() })
  }, [type])

  const Logo = Helpers.config.resourcesUrl({ url: '/images/dashboard/logo.png' })

  return <>
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      background: 'linear-gradient(135deg, #ffffff 43%,#aac170 100%)'
    }}></div>
    <Box backgroundImage={imageBackground}>
      <Box.Container>
        <Box.Content className="form-row">
          <div className="col-12 text-center mb-5">
            <Box.Logo src={Logo} className="box-bg-content-logo" alt="" />
          </div>

          <form onSubmit={handleSubmit} autoComplete="off" className="col-12 needs-validation" noValidate>
            <div className="form-row">
              <Input
                placeholder="Nombre de usuario"
                icon="fa fa-user"
                classNameParent="col-12 mb-3"
                required
                defaultValue={session.username}
                onChange={e => setSession({ ...session, username: e.currentTarget.value })}
                size=""
              />
              <Input type="password" 
                placeholder="Contraseña"
                icon="fa fa-lock"
                classNameParent="col-12 mb-2"
                required
                defaultValue={session.password}
                onChange={e => setSession({ ...session, password: e.currentTarget.value })}
                size=""
              />
              <CheckBox text="Recordarme" defaultChecked={false} classNameParent="col-12 text-white" id="remember_me" />
              <div className="col-12 mt-4">
                <Button type="submit" variant="success" size="" block style={{
                  height: '50px'
                }}>
                  INGRESAR
                </Button>
              </div>
            </div>
          </form>

        </Box.Content>
      </Box.Container>
    </Box>
  </>
}

export default Login