import React, { useState, useEffect, useCallback } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import { useSelector } from 'react-redux'
import ReactPlaceholder from 'react-placeholder/lib'
import { CheckBox, FormCustom, Input, SwitchToggle } from '../../Components/Form/Form'
import Button from 'react-bootstrap/Button'
import Helpers from '../../Components/Helpers/Helpers'
import { useParams } from 'react-router-dom'
import Loading from '../../Components/Layout/Styled/Loading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const Cart = _ => {
    const { type } = useParams()
    const history = useHistory()

    const [taxValue, setTaxValue] = useState(0)
    const [shippingFree, setShippingFree] = useState({
        shipping_free: 0,
        shipping_value: 0
    })
    const [showLoading, setShowLoading] = useState(false)

    const [customers, setCustomers] = useState({})
    const [details, setDetails] = useState([])
    const [ready, setReady] = useState(false)
    const [preview, setPreview] = useState(true)
    const [existsStock, setExistsStock] = useState({
        message: '',
        products: []
    })

    useEffect(() => {
        Promise.all([
            Controllers.cart.get_cart_det(),
            Controllers.cart.get_cart_customers()
        ]).then(res => {
            setDetails(res[0].data)
            setCustomers(res[1].data)
            setTaxValue(parseFloat(res[1].data.value_tax))
            setShippingFree({
                shipping_free: parseFloat(res[1].data.shipping_free),
                shipping_value: parseFloat(res[1].data.shipping_value)
            })
            setReady(true)
        })
    }, [preview])

    const getSubtotal = useCallback(() => {
        let total = 0
        details.forEach(r => {
            let subt = parseFloat(r.qty_product) * parseFloat(r.price_product)
            if (!isNaN(parseFloat(r.qty_product) * parseFloat(r.price_product))) {
                total += subt
            }
        })

        let shipping = total < shippingFree.shipping_free ? (parseInt(customers.pick_up_branch_office) === 1 ? 0 : shippingFree.shipping_value) : 0
        total = total + shipping
        let subtotal = parseFloat(total / (1 + taxValue)).toFixed(2)

        return {
            total: total.toFixed(2),
            subtotal,
            iva: (total - parseFloat(total / (1 + taxValue))).toFixed(2),
            shipping: shipping.toFixed(2),
            total_with_shipping: (total).toFixed(2)
        }
    }, [details, taxValue, shippingFree, customers])

    const dataSubmit = useCallback(() => {
        let dets = []

        details.forEach(d => {
            dets.push({
                p_id_product: d.id_product,
                p_qty: d.qty_product,
                p_price: d.price_product,
                p_lot: d.lot_product,
                p_point: d.point_product,
                p_value_business: d.value_business_product,
                p_pack_product: d.pack_product
            })
        })

        return {
            p_pick_up_branch_office: customers.pick_up_branch_office,
            p_details: dets
        }
    }, [ details, customers ])

    return <>
        {type === 'error-pago' ? <div className="alert alert-danger">
            El pago fue cancelado. Por favor, vuelva a intentar
        </div> : ''}
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span className="align-self-center">Mi Carrito de Compras</span>
                <Button size="sm" variant="secondary" onClick={_ => history.push(Helpers.config.convertUrl({ url: '/ecommerce' }))}>
                    <i className="fa fa-shopping-cart text-white cur-pointer"></i> Seguir Comprando
                </Button>
            </Card.Header>
        </Card>
        <Card className="mb-4">
            <Card.Header>
                Información de Cliente
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder rows={5} showLoadingAnimation ready={ready}>
                    <div className="form-row">
                        <Input
                            text="Nombre de Cliente"
                            defaultValue={customers.full_name_customers}
                            classNameParent="col-6"
                            disabled
                        />
                        <Input
                            text="Dirección"
                            defaultValue={customers.address_customers}
                            classNameParent="col-6 mb-2"
                            disabled
                        />
                        <SwitchToggle
                            id="checkbox_pick_up_branch_office"
                            defaultChecked={parseInt(customers.pick_up_branch_office) === 1}
                            textCheck="¿Recoger en Sucursal?"
                            textUnChecked="¿Recoger en Sucursal?"
                            onChange={e => setCustomers({ ...customers, pick_up_branch_office: e.currentTarget.checked ? 1 : 0 })}
                            disabled={!preview}
                        />
                    </div>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Card className="mb-4">
            <Card.Header>
                Listado de Productos
            </Card.Header>
            <Card.Body>
                {existsStock?.products?.length > 0 ? <div className="alert alert-warning d-flex flex-column">
                    <span>{existsStock.message}</span>
                    <span className="mt-2 mb-2">Detalle</span>

                    {React.Children.toArray(existsStock.products.map(p => <>
                        <span>Producto: <strong>{p.key_product}</strong></span>
                        <span>Cantidad Solicitada: <strong>{p.qty_request}</strong></span>
                        <span>Stock: <strong>{p.qty}</strong></span>
                    </>))}
                </div> : ''}
                <FormCustom onSubmit={_ => {
                    setShowLoading(true)
                    Controllers.cart.cart_det_mass_insert_update(dataSubmit()).then(res => {
                        if (res.response !== 'success') {
                            setExistsStock({
                                message: res.message,
                                products: res.data
                            })
                            setPreview(true)
                            return
                        }
                        setExistsStock({
                            message: '',
                            products: []
                        })
                        setReady(false)
                        setPreview(false)
                    }).catch(_ => window.location.reload())
                        .finally(_ => setShowLoading(false))
                }} viewSubmit={false}>
                    <div className="table-responsive">
                        <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
                            <table className="table table-sm table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                        <th>Precio</th>
                                        <th>Puntos</th>
                                        <th>Valor Negocio</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {React.Children.toArray(details.map(r => <tr>
                                        <td>{r.description_store_product}</td>
                                        <td className="text-center">
                                            {preview ? <Input
                                                className="text-center"
                                                required
                                                type="number"
                                                min="1"
                                                /* max={r.exists_current_qty_product} */
                                                /* invalid={'Stock: ' + r.exists_current_qty_product} */
                                                step="1"
                                                value={!isNaN(parseInt(r.qty_product)) ? r.qty_product : ''}
                                                onChange={e => {
                                                    setDetails(details.map(rr => {
                                                        if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                            rr.qty_product = e.currentTarget.value
                                                        }
                                                        return rr
                                                    }))
                                                    setExistsStock({
                                                        message: '',
                                                        products: []
                                                    })
                                                }}
                                                style={{
                                                    minWidth: '100px'
                                                }}
                                            /> : !isNaN(parseInt(r.qty_product)) ? r.qty_product : ''}
                                        </td>
                                        <td className="text-center">
                                            {parseFloat(r.price_product).toFixed(2)}
                                        </td>
                                        <td className="text-center">
                                            {parseFloat(r.point_product).toFixed(2)}
                                        </td>
                                        <td className="text-center">
                                            {parseFloat(r.value_business_product).toFixed(2)}
                                        </td>
                                        <td>
                                            {preview ? <Button size="xs" variant="danger" onClick={() => {
                                                setExistsStock({
                                                    message: '',
                                                    products: []
                                                })
                                                setDetails(details.filter(rr => parseInt(rr.id_product) !== parseInt(r.id_product)))
                                                Controllers.cart.cart_det_delete({
                                                    p_id_product: r.id_product
                                                })
                                            }}>
                                                <i className="fa fa-trash"></i>
                                            </Button> : ''}
                                        </td>
                                    </tr>))}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="center">Costo de envío</td>
                                        <td align="center">{getSubtotal().shipping} {customers.code_money}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="center">Subtotal</td>
                                        <td align="center">{getSubtotal().subtotal} {customers.code_money}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="center">IVA</td>
                                        <td align="center">{getSubtotal().iva} {customers.code_money}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="center">Total</td>
                                        <td align="center"><strong>{getSubtotal().total} {customers.code_money}</strong></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="center">Total a Pagar</td>
                                        <td align="center"><strong>{getSubtotal().total_with_shipping} {customers.code_money}</strong></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                            {details.length === 0 ? '' : (
                                preview ? <Button type="submit" variant="info" block>
                                    <i className="fa fa-dollar-sign"></i> Pagar Ahora
                                </Button> : <>
                                    <Button type="button" variant="paypal" block onClick={_ => {
                                        setShowLoading(true)
                                        Controllers.cart.paypal_cart().then(res => {
                                            if (res.response !== 'success') {
                                                Helpers.toast.construct({ ...res, toast })
                                                setShowLoading(false)
                                                return
                                            }
                                            setShowLoading(false)
                                            const formPaypalContent = document.getElementById('formPaypalContent')
                                            formPaypalContent.innerHTML = res.data
                                            formPaypalContent.querySelector('form').submit()
                                        })
                                    }}>
                                        <i className="fab fa-paypal"></i> Pagar con Paypal
                                    </Button>
                                    {customers.code_money === 'MXN' ? <Button type="button" variant="info" block onClick={_ => {
                                        setShowLoading(true)
                                        Controllers.cart.mercadopago_cart().then(res => {
                                            if (res.response !== 'success') {
                                                Helpers.toast.construct({ ...res, toast })
                                                setShowLoading(false)
                                                return
                                            }
                                            setShowLoading(false)
                                            window.location.href = res.data.link_pay
                                        })
                                    }}>
                                        Pagar con Mercado Pago
                                    </Button> : ''}
                                    <Button type="button" variant="secondary" block onClick={_ => setPreview(true)}>
                                        <i className="fa fa-times"></i> Cancelar
                                    </Button>
                                </>
                            )}
                        </ReactPlaceholder>
                    </div>
                </FormCustom>
            </Card.Body>
        </Card>
        <Loading show={showLoading}>
            <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
        </Loading>
        <div id="formPaypalContent"></div>
    </>
}

export default Cart