import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import SaleForm from './SaleForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux'

const Sale = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const [idDocumentPdf, setIdDocumentPdf] = useState('')
    const [modalShowPdf, setModalShowPdf] = useState(false);

    const jwt = useSelector(store => store.session.jwt)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.sale.get_sale().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.sale.sale_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
                setIdDocumentPdf(res.data.id)
                setModalShowPdf(true)
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idDocument) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idDocument } = $this.dataset

        Controllers.sale.get_sale_id({ p_id_sale: idDocument }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idDocument}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.sale.sale_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idDocument, nameDocument } = e.currentTarget.dataset
        setDataDeleted({
            p_id_document: idDocument
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_document', text: 'ID', align: 'center' },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'name_branch_office', text: 'Nombre', align: 'center' },
                { name: 'full_name_customers', text: 'Cliente' },
                { name: 'date_doc', text: 'Fecha', align: 'center' },
                { name: 'total_doc', text: 'Total', align: 'center' },
                { name: 'code_period', text: 'Periodo', align: 'center' },
                { name: 'source_doc', text: 'Tipo Venta', align: 'center', render: r => {
                    let text = 'VENTA'
                    let color = 'success'

                    switch (r.source_doc) {
                        case 'ECOMMERCE':
                            text = 'ECOMMERCE'
                            color = 'dark'
                            break;
                        case 'PENDIENTE':
                            text = 'PENDIENTE'
                            color = 'warning'
                            break;
                        case 'PAGADO':
                            text = 'PAGADO'
                            color = 'info'
                            break;
                    
                        default:
                            break;
                    }

                    return <div className={'badge badge-' + (color)}>
                        {text}
                    </div>
                } },
                { name: 'has_invoice_doc', text: 'Factura', align: 'center', render: r => {
                    return <div className={'badge badge-' + (parseInt(r.has_invoice_doc) === 1 ? 'success' : 'danger')}>
                        {parseInt(r.has_invoice_doc) === 1 ? 'Si' : 'No'}
                    </div>
                } },
                { name: 'pick_up_branch_office', text: 'Recojo Sucursal', align: 'center', render: r => {
                    return <div className={'badge badge-' + (parseInt(r.pick_up_branch_office) === 1 ? 'success' : 'danger')}>
                        {parseInt(r.pick_up_branch_office) === 1 ? 'Si' : 'No'}
                    </div>
                } },
                { name: 'id_dispatch', text: 'Despacho', align: 'center', render: r => {
                    return <div className={'badge badge-' + r.color_dispatch}>
                        {r.name_dispatch}
                    </div>
                } },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Button variant="info" className="mr-1" size="xs" data-id-document={u.id_document} onClick={handleGet}>
                            <i className="fa fa-edit"></i>
                        </Button>
                        <Button variant="danger" size="xs" data-id-document={u.id_document} onClick={handleShowModalDelete}>
                            <i className="fa fa-trash"></i>
                        </Button>
                        <Button variant="danger" className="ml-1" size="xs" onClick={_ => {
                            setIdDocumentPdf(u.id_document)
                            setModalShowPdf(true)
                        }}>
                            <i className="fa fa-file-pdf"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
        columnOrder="0"
        orderDirection="desc"
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="xl">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <SaleForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowPdf} onHide={_ => setModalShowPdf(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">Documento de la Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="w-100" style={{ 
                    minHeight: 500
                 }} src={Helpers.config.apiUrl({ url: '/sale/generate/invoice/' + idDocumentPdf + '?jwt=' + jwt })}></iframe>
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_document} - {dataDeleted.p_name_sale}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Sale