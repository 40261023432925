import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import Slider from "react-slick";
import Helpers from '../../Components/Helpers/Helpers';
import ReactPlaceholder from 'react-placeholder/lib'
import Button from 'react-bootstrap/Button'
import CustomersEcommerceForm from './CustomersEcommerceForm';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import {useHistory} from 'react-router-dom'

const Ecommerce = _ => {
    const [clasifications, setClasifications] = useState([])
    const [ready, setReady] = useState(false)
    const [readyProducts, setReadyProducts] = useState(false)
    const [clasificationSelected, setClasificationSelected] = useState('')
    const [productsByClasification, setProductsByClasification] = useState([])

    const [customersSelected, setCustomersSelected] = useState({})
    const [customersChange, setCustomersChange] = useState({})
    const [readyCustomers, setReadyCustomers] = useState(false)

    const [showFormCustomers, setShowFormCustomers] = useState(false)
    const [showCart, setShowCart] = useState(false)

    const history = useHistory()

    const [imagesBanner, setImagesBanner] = useState([])

    useEffect(() => {
        Promise.all([
            Controllers.clasification.get_clasification_ecommerce(),
            Controllers.text.get_text_id({ p_id_text: 3 })
        ]).then(res => {
            setClasifications(res[0].data)
            setImagesBanner(res[1].data)

            if (res[0].data.length > 0) {
                setClasificationSelected(res[0].data[0].id_clasification)
            } else {
                setReadyProducts(true)
            }
        })
    }, [])

    useEffect(() => {
        if (!readyCustomers) {
            Controllers.cart.get_cart_customers().then(res => {
                setReadyCustomers(true)
                setCustomersSelected(res.data)
                setCustomersChange(res.data)
            })
        }
    }, [readyCustomers])

    useEffect(() => {
        if (clasificationSelected && !readyProducts) {
            setReadyProducts(false)
            Controllers.product.get_product_by_customers({
                p_id_clasification: clasificationSelected
            }).then(res => {
                setReadyProducts(true)
                setProductsByClasification(res.data)
            })
        }
    }, [clasificationSelected, readyProducts])

    const products = useMemo(() => <ReactPlaceholder ready={readyProducts} showLoadingAnimation rows={10}>    
        <Card>
            <Card.Body className="pb-4">
                <Slider {...{
                    dots: true,
                    arrows: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                          }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                          }
                        }
                      ]
                }}>
                    {React.Children.toArray(productsByClasification.map(r => {
                        return <div className={'pr-4'}>
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center" style={{
                                    height: 300
                                }}>
                                    <img src={Helpers.config.resourcesUrl({ url: r.file_photo })} className="img-fluid mb-2 align-self-center" style={{ width: 190, maxHeight: 300 }} />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center d-flex flex-column">
                                        <strong className="mt-">{r.description_store_product}</strong>
                                        <strong className="mt-">$ {r.price_product}</strong>
                                        <small className="mt-">Puntos {r.point_product}</small>
                                        <small className="mt-">Valor Negocio {r.value_business_product}</small>
                                    </div>
                                    <div className="align-self-center">
                                        <Button size="sm" variant={'outline-primary'} onClick={_ => {
                                            Controllers.cart.cart_det_insert_update({
                                                p_id_product: r.id_product,
                                                p_qty: 1,
                                                p_price: r.price_product,
                                                p_lot: '',
                                                p_point: r.point_product,
                                                p_value_business: r.value_business_product,
                                            }).then(_ => {
                                                setShowCart(true)
                                            })
                                        }}>
                                            <i className="fa fa-shopping-cart"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                        </div>
                    }))}
                </Slider>
            </Card.Body>
        </Card>
    </ReactPlaceholder>, [readyProducts, productsByClasification])

    return <>
    <div className="form-row">
        <div className="col-12 mb-4">
            <Card>
                <Card.Body>
                    <ReactPlaceholder ready={readyCustomers} showLoadingAnimation rows={1}>
                        <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                            Cliente de la Venta: <strong>{customersSelected.id_customers_sale} - {customersSelected.full_name_customers}</strong>
                            <Button size="xs" className="ml-2" onClick={_ => setShowFormCustomers(true)}>
                                <i className="fa fa-edit"></i>
                            </Button>
                            </div>
                            <div>
                                <Button size="sm" className="ml-2" onClick={_ => setShowFormCustomers(true)} onClick={_ => {
                                    history.push(Helpers.config.convertUrl({ url: '/carrito' }))
                                }}>
                                    <i className="fa fa-shopping-cart"></i> Ir a Carrito
                                </Button>  
                            </div>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        {showFormCustomers ? <>
            <div className="col-6">
                <Card>
                    <Card.Body>
                        <CustomersEcommerceForm customers={customersChange} reloadCustomers={_ => {
                            setReadyCustomers(false)
                            setReadyProducts(false)
                            setShowFormCustomers(false)
                        }} cancelCustomers={_ => setShowFormCustomers(false)} />
                    </Card.Body>
                </Card>
            </div>
        </> : <>
            <div className="col-12">
                <Card>
                    <Card.Body>
                        <Slider {...{
                            infinite: true,
                            speed: 500,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 3000
                        }}>
                            {React.Children.toArray(imagesBanner?.file_image_text?.map(b => <div className="d-flex justify-content-center">
                                <img src={Helpers.config.resourcesUrl({ url: b })} className="img-fluid" style={{ maxHeight: 400, width: '90%' }} />
                            </div>))}
                        </Slider>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-12 mt-4">
                <div className="d-flex" style={{
                    overflowX: 'auto'
                }}>
                    {React.Children.toArray(clasifications.map(c => <div className={(parseInt(c.id_clasification) === parseInt(clasificationSelected) ? 'bg-success text-white' : 'bg-white') + ' rounded p-2 mr-2 w-50 cur-pointer hover-bg-success text-center'}  onClick={_ => {
                        setClasificationSelected(c.id_clasification)
                        setReadyProducts(false)
                    }}>
                        {c.name_clasification}
                    </div>))}
                </div>
            </div>
            <div className="col-12 mt-4">
                {products}
            </div>
        </>}
    </div>
    <SweetAlert
        icon="shopping"
        show={showCart}
        showCancelButton
        title={<>Producto agregado al carrito</>}
        subtitle={<>¿Desea pagar ahora?</>}
        confirmButtonText="¡Sí, Pagar!"
        cancelButtonText="¡Seguir Comprando!"
        onCancel={() => setShowCart(false)}
        onConfirm={() => {
            history.push(Helpers.config.convertUrl({ url: '/carrito' }))
        }}
    />
    </>
}

export default Ecommerce