import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../../Components/Helpers/Helpers'
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap'
import Modal from 'react-bootstrap/Modal'
import { FormCustom, Select } from '../../Components/Form/Form'
import { toast } from 'react-toastify'

const CopyProducts = _ => {
    const [ready, setReady] = useState(false)
    const [rowsBranchOffice, setRowsBrachOffice] = useState([])
    const [rowProduct, setRowProduct] = useState({
        p_id_branch_office_origin: '',
        p_id_branch_office_destination: ''
    })
    const [rowProductPrice, setRowProductPrice] = useState({
        p_id_branch_office_origin: '',
        p_id_branch_office_destination: ''
    })
    const [loadSubmitProduct, setLoadSubmitProduct] = useState(false)
    const [loadSubmitProductPrice, setLoadSubmitProductPrice] = useState(false)

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            setRowsBrachOffice(res.data)
            setReady(true)
        })
    }, [])

    const branchOfficeOriginProduct = useMemo(() => {
        if (!rowProduct.p_id_branch_office_origin) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProduct.p_id_branch_office_origin))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProduct.p_id_branch_office_origin, rowsBranchOffice])

    const branchOfficeDestinationProduct = useMemo(() => {
        if (!rowProduct.p_id_branch_office_destination) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProduct.p_id_branch_office_destination))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProduct.p_id_branch_office_destination, rowsBranchOffice])

    const branchOfficeOriginProductPrice = useMemo(() => {
        if (!rowProductPrice.p_id_branch_office_origin) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProductPrice.p_id_branch_office_origin))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProductPrice.p_id_branch_office_origin, rowsBranchOffice])

    const branchOfficeDestinationProductPrice = useMemo(() => {
        if (!rowProductPrice.p_id_branch_office_destination) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProductPrice.p_id_branch_office_destination))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProductPrice.p_id_branch_office_destination, rowsBranchOffice])

    const onSubmitProduct = ({ data }) => {
        setLoadSubmitProduct(true)
        Controllers.branch_office.branch_office_copy_products(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmitProduct(false)
        })
    }

    const onSubmitProductPrice = ({ data }) => {
        setLoadSubmitProductPrice(true)
        Controllers.branch_office.branch_office_copy_products_price(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmitProductPrice(false)
        })
    }

    return <>
        <Card className="mb-4">
            <Card.Header>
                Replicar definición de producto a otro almacén
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom loadSubmit={loadSubmitProduct} dataSubmit={rowProduct} onSubmit={({ data }) => {
                        if (!rowProduct.p_id_branch_office_origin || !rowProduct.p_id_branch_office_destination) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales', toast })
                            return
                        }

                        if (parseInt(rowProduct.p_id_branch_office_origin) === parseInt(rowProduct.p_id_branch_office_destination)) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales diferentes', toast })
                            return
                        }

                        onSubmitProduct({ data })
                    }} textButton="Replicar">
                        <Select
                            text="Sucursal Origen"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office
                                }
                            })}
                            value={branchOfficeOriginProduct}
                            onChange={e => setRowProduct({ ...rowProduct, p_id_branch_office_origin: e.value })}
                            classNameParent="col-12 mb-2"
                        />
                        <Select
                            text="Sucursal Destino"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office
                                }
                            })}
                            value={branchOfficeDestinationProduct}
                            onChange={e => setRowProduct({ ...rowProduct, p_id_branch_office_destination: e.value })}
                            classNameParent="col-12 mb-2"
                        />
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Card className="mb-4">
            <Card.Header>
                Replicar precios de productos a otro almacén
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom loadSubmit={loadSubmitProductPrice} dataSubmit={rowProductPrice} onSubmit={({ data }) => {
                        if (!rowProductPrice.p_id_branch_office_origin || !rowProductPrice.p_id_branch_office_destination) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales', toast })
                            return
                        }

                        if (parseInt(rowProductPrice.p_id_branch_office_origin) === parseInt(rowProductPrice.p_id_branch_office_destination)) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales diferentes', toast })
                            return
                        }

                        onSubmitProductPrice({ data })
                    }} textButton="Replicar">
                        <Select
                            text="Sucursal Origen"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office
                                }
                            })}
                            value={branchOfficeOriginProductPrice}
                            onChange={e => setRowProductPrice({ ...rowProductPrice, p_id_branch_office_origin: e.value })}
                            classNameParent="col-12 mb-2"
                        />
                        <Select
                            text="Sucursal Destino"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office
                                }
                            })}
                            value={branchOfficeDestinationProductPrice}
                            onChange={e => setRowProductPrice({ ...rowProductPrice, p_id_branch_office_destination: e.value })}
                            classNameParent="col-12 mb-2"
                        />
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default CopyProducts