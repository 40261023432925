import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, TextArea, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ProductList from '../Product/ProductList'
import { toast } from 'react-toastify';

const InventoryForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_inventory: data.id_inventory,
        p_name_inventory: data.name_inventory,
        p_date_inventory: data.date_inventory,
        p_id_branch_office: data.id_branch_office,
        p_details: data.details,
    })

    const [ready, setReady] = useState(false)
    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [modalProducts, setModalProducts] = useState(false)

    const handleShowModalProducts = () => {
        if (row.p_id_branch_office) {
            setModalProducts(true)
        } else {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Sucursal', toast })
        }
    }
    const handleHideModalProducts = () => setModalProducts(false)

    useEffect(() => {
        let promises = [
            Controllers.branch_office.get_branch_office()
        ]

        Promise.all(promises).then(res => {
            setRowsBranchOffice(res[0].data)
            setReady(true)
        })
    }, [])
    
    const idBranchOffice = useMemo(() => {
        if (!row.p_id_branch_office) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, rowsBranchOffice])

    const getTotalDifference = useCallback(() => {
        let total = 0, qty_total = 0
        row.p_details.forEach(r => {
            let qtyDifference = parseInt(r.qty_difference)
            qty_total += qtyDifference * (qtyDifference < 0 ? -1 : 1)
            total += parseFloat(r.subtotal)
        })

        return {
            qty: qty_total,
            total: total.toFixed(2)
        }
    }, [row.p_details])

    const dataSubmit = useCallback(() => {
        return  {
            ...row,
            p_details: row.p_details.map(r => {
                return {
                    p_id_product: r.id_product,
                    p_key_product: r.key_product,
                    p_name_product: r.name_product,
                    p_qty_system: r.qty_system,
                    p_price: r.price,
                    p_qty_physic: r.qty_physic,
                    p_qty_difference: r.qty_difference,
                    p_subtotal: r.subtotal,
                }
            }),
            p_qty_difference_total_inventory: getTotalDifference().qty,
            p_total_inventory: getTotalDifference().total
        }
    }, [row, getTotalDifference])

    return <ReactPlaceholder rows={7} ready={ready}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_id_branch_office) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione Sucursal.', toast })
                return
            }

            if (row.p_details.length === 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos.', toast })
                return
            }

            return onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Nombre Inventario"
                placeholder="Nombre Inventario"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_inventory}
                onChange={e => setRow({ ...row, p_name_inventory: e.currentTarget.value })}
                maxLength={100}
            />
            <Input
                text="Fecha"
                placeholder="Fecha"
                type="date"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_inventory}
                onChange={e => setRow({ ...row, p_date_inventory: e.currentTarget.value })}
            />
            <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text="Sucursal"
                options={rowsBranchOffice.map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={idBranchOffice}
                onChange={e => setRow({ ...row, p_id_branch_office: e.value, p_details: [] })}
            />
            <div className="mb-2 col-12">
                <Button size="sm" onClick={handleShowModalProducts}>
                    <i className="fa fa-plus mr-1"></i>Agregar Producto
                </Button>
            </div>
            
            <div className="table-responsive col-12">
                <table className="table table-sm table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Clave</th>
                            <th>Descripción</th>
                            <th>Piezas en el Sistema</th>
                            <th>Piezas en Físico</th>
                            <th>Diferencia</th>
                            <th>Costo Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(row.p_details.map(r => <tr>
                            <td className="text-center">{r.key_product}</td>
                            <td className="text-center">{r.name_product}</td>
                            <td className="text-center">
                                {r.qty_system}
                            </td>
                            <td>
                                <Input
                                    className="text-center"
                                    required
                                    type="number"
                                    min="0"
                                    step="1"
                                    value={!isNaN(parseInt(r.qty_physic)) ? r.qty_physic : ''}
                                    onChange={e => {
                                        setRow({
                                            ...row,
                                            p_details: row.p_details.map(rr => {
                                                if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                    rr.qty_physic = e.currentTarget.value
                                                    if (!isNaN(parseInt(rr.qty_physic))) {
                                                        rr.qty_difference = parseInt(rr.qty_physic) - parseInt(rr.qty_system)
                                                        if (rr.qty_difference < 0) {
                                                            rr.subtotal = parseFloat(rr.price) * (parseInt(rr.qty_difference) * -1)
                                                        } else {
                                                            rr.subtotal = 0
                                                        }
                                                    } else {
                                                        rr.qty_physic = ''
                                                        rr.subtotal = 0
                                                        rr.qty_difference = 0
                                                    }
                                                }
                                                return rr
                                            })
                                        })
                                    }}
                                    style={{
                                        minWidth: '100px'
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                {r.qty_difference}
                            </td>
                            <td className="text-center">
                                {parseFloat(r.subtotal).toFixed(2)}
                            </td>
                            <td>
                                <Button size="xs" variant="danger" onClick={() => {
                                    setRow({ ...row, p_details: row.p_details.filter(rr => parseInt(rr.id_product) !== parseInt(r.id_product)) })
                                }}>
                                    <i className="fa fa-trash"></i>
                                </Button>
                            </td>
                        </tr>))}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td align="center">Subtotal</td>
                            <td align="center">{getTotalDifference().qty}</td>
                            <td align="center">{getTotalDifference().total}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </FormCustom>
        <ProductList showModal={modalProducts} viewPack={false} hideModal={handleHideModalProducts} nameSingle="Producto" namePlural="Productos" separator="el" added={row.p_details} idBranchOffice={row.p_id_branch_office} addItem={({ idProduct, keyProduct, nameProduct, qtyExists, priceProduct }) => {
            let newDet = ({
                id_product: idProduct,
                key_product: keyProduct,
                name_product: nameProduct,
                qty_system: qtyExists,
                price: priceProduct,
                qty_physic: '',
                qty_difference: 0,
                subtotal: 0
            })
            setRow({ ...row, p_details: [ ...row.p_details, newDet ] })
        }} />
    </ReactPlaceholder>
}

InventoryForm.defaultProps = {
    data: {
        id_inventory: '',
        name_inventory: '',
        date_inventory: '',
        id_branch_office: '',
        details: []
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default InventoryForm;