import Helpers from '../Components/Helpers/Helpers';

const Controllers = {
    users: {
        get_login: (data = {}) => Helpers.axios({ url: '/users/login', method: 'POST', data }),
        get_users: () => Helpers.axios({ url: '/users/get/all' }),
        get_personal_information: () => Helpers.axios({ url: '/users/get/personal/information' }),
        get_users_id: ({ p_id_user }) => Helpers.axios({ url: '/users/get/id/' + p_id_user }),
        users_insert_update: (data = {}) => Helpers.axios({ url: '/users/insert/update', method: 'POST', data }),
        users_delete: (data = {}) => Helpers.axios({ url: '/users/delete', method: 'POST', data })
    },
    profile: {
        get_profile: () => Helpers.axios({ url: '/profile/get/all' }),
        get_profile_id: ({ p_id_profile }) => Helpers.axios({ url: '/profile/get/id/' + p_id_profile }),
        profile_insert_update: (data = {}) => Helpers.axios({ url: '/profile/insert/update', method: 'POST', data }),
        profile_delete: (data = {}) => Helpers.axios({ url: '/profile/delete', method: 'POST', data })
    },
    tags_items: {
        get_tags_items_profile: ({ p_id_profile = '' } = {}) => Helpers.axios({ url: '/tagsitems/profile/get/all/' + p_id_profile }),
    },
    type_money: {
        get_type_money: () => Helpers.axios({ url: '/typemoney/get/all' }),
        get_type_money_id: ({ p_id_type_money }) => Helpers.axios({ url: '/typemoney/get/id/' + p_id_type_money }),
        type_money_insert_update: (data = {}) => Helpers.axios({ url: '/typemoney/insert/update', method: 'POST', data }),
        type_money_delete: (data = {}) => Helpers.axios({ url: '/typemoney/delete', method: 'POST', data })
    },
    type_round: {
        get_type_round: () => Helpers.axios({ url: '/typeround/get/all' }),
        get_type_round_id: ({ p_id_type_round }) => Helpers.axios({ url: '/typeround/get/id/' + p_id_type_round }),
        type_round_insert_update: (data = {}) => Helpers.axios({ url: '/typeround/insert/update', method: 'POST', data }),
        type_round_delete: (data = {}) => Helpers.axios({ url: '/typeround/delete', method: 'POST', data })
    },
    language: {
        get_language: () => Helpers.axios({ url: '/language/get/all' }),
        get_language_id: ({ p_id_language }) => Helpers.axios({ url: '/language/get/id/' + p_id_language }),
        language_insert_update: (data = {}) => Helpers.axios({ url: '/language/insert/update', method: 'POST', data }),
        language_delete: (data = {}) => Helpers.axios({ url: '/language/delete', method: 'POST', data })
    },
    country: {
        get_country: () => Helpers.axios({ url: '/country/get/all' }),
        get_country_id: ({ p_id_country }) => Helpers.axios({ url: '/country/get/id/' + p_id_country }),
        country_insert_update: (data = {}) => Helpers.axios({ url: '/country/insert/update', method: 'POST', data }),
        country_delete: (data = {}) => Helpers.axios({ url: '/country/delete', method: 'POST', data })
    },
    type_format_print: {
        get_type_format_print: () => Helpers.axios({ url: '/typeformatprint/get/all' }),
        get_type_format_print_id: ({ p_id_type_format_print }) => Helpers.axios({ url: '/typeformatprint/get/id/' + p_id_type_format_print }),
        type_format_print_insert_update: (data = {}) => Helpers.axios({ url: '/typeformatprint/insert/update', method: 'POST', data }),
        type_format_print_delete: (data = {}) => Helpers.axios({ url: '/typeformatprint/delete', method: 'POST', data })
    },
    branch_office: {
        get_branch_office: () => Helpers.axios({ url: '/branchoffice/get/all' }),
        get_branch_office_id: ({ p_id_branch_office }) => Helpers.axios({ url: '/branchoffice/get/id/' + p_id_branch_office }),
        branch_office_insert_update: (data = {}) => Helpers.axios({ url: '/branchoffice/insert/update', method: 'POST', data }),
        branch_office_delete: (data = {}) => Helpers.axios({ url: '/branchoffice/delete', method: 'POST', data }),
        branch_office_copy_products: (data = {}) => Helpers.axios({ url: '/branchoffice/copy/products', method: 'POST', data }),
        branch_office_copy_products_price: (data = {}) => Helpers.axios({ url: '/branchoffice/copy/products/price', method: 'POST', data }),
        get_branch_office_tax_shipping: ({ p_id_branch_office }) => Helpers.axios({ url: '/branchoffice/get/tax/shipping/' + p_id_branch_office }),
    },
    tax: {
        get_tax: () => Helpers.axios({ url: '/tax/get/all' }),
        get_tax_id: ({ p_id_tax }) => Helpers.axios({ url: '/tax/get/id/' + p_id_tax }),
        tax_insert_update: (data = {}) => Helpers.axios({ url: '/tax/insert/update', method: 'POST', data }),
        tax_delete: (data = {}) => Helpers.axios({ url: '/tax/delete', method: 'POST', data })
    },
    exempt: {
        get_exempt: () => Helpers.axios({ url: '/exempt/get/all' }),
        get_exempt_id: ({ p_id_exempt }) => Helpers.axios({ url: '/exempt/get/id/' + p_id_exempt }),
        exempt_insert_update: (data = {}) => Helpers.axios({ url: '/exempt/insert/update', method: 'POST', data }),
        exempt_delete: (data = {}) => Helpers.axios({ url: '/exempt/delete', method: 'POST', data })
    },
    bank: {
        get_bank: () => Helpers.axios({ url: '/bank/get/all' }),
        get_bank_id: ({ p_id_bank }) => Helpers.axios({ url: '/bank/get/id/' + p_id_bank }),
        bank_insert_update: (data = {}) => Helpers.axios({ url: '/bank/insert/update', method: 'POST', data }),
        bank_delete: (data = {}) => Helpers.axios({ url: '/bank/delete', method: 'POST', data })
    },
    type_format_pay: {
        get_type_format_pay: () => Helpers.axios({ url: '/typeformatpay/get/all' }),
        get_type_format_pay_id: ({ p_id_type_format_pay }) => Helpers.axios({ url: '/typeformatpay/get/id/' + p_id_type_format_pay }),
        type_format_pay_insert_update: (data = {}) => Helpers.axios({ url: '/typeformatpay/insert/update', method: 'POST', data }),
        type_format_pay_delete: (data = {}) => Helpers.axios({ url: '/typeformatpay/delete', method: 'POST', data })
    },
    customers: {
        get_customers: () => Helpers.axios({ url: '/customers/get/all', method: 'POST' }),
        get_search_my_refer: ({ p_id_customers }) => Helpers.axios({ url: '/customers/search/refer/' + p_id_customers }),
        get_customers_public: () => Helpers.axios({ url: '/customers/get/public', method: 'POST' }),
        get_customers_id: ({ p_id_customers }) => Helpers.axios({ url: '/customers/get/id/' + p_id_customers }),
        customers_insert_update: (data = {}) => Helpers.axios({ url: '/customers/insert/update', method: 'POST', data, hasFile: true }),
        customers_delete: (data = {}) => Helpers.axios({ url: '/customers/delete', method: 'POST', data }),
        get_customers_bank: ({ p_id_customers }) => Helpers.axios({ url: '/customers/bank/get/all/' + p_id_customers }),
        get_customers_social: ({ p_id_customers }) => Helpers.axios({ url: '/customers/social/get/all/' + p_id_customers }),
        get_customers_validate: ({ p_id_customers }) => Helpers.axios({ url: '/customers/get/validate/' + p_id_customers }),
        customers_information_update: (data = {}) => Helpers.axios({ url: '/customers/information/update', method: 'POST', data, hasFile: true }),
        get_customers_new: () => Helpers.axios({ url: '/customers/get/new' }),
    },
    type_price: {
        get_type_price: () => Helpers.axios({ url: '/typeprice/get/all' }),
        get_type_price_id: ({ p_id_type_price }) => Helpers.axios({ url: '/typeprice/get/id/' + p_id_type_price }),
        type_price_insert_update: (data = {}) => Helpers.axios({ url: '/typeprice/insert/update', method: 'POST', data }),
        type_price_delete: (data = {}) => Helpers.axios({ url: '/typeprice/delete', method: 'POST', data })
    },
    clasification: {
        get_clasification: () => Helpers.axios({ url: '/clasification/get/all' }),
        get_clasification_ecommerce: () => Helpers.axios({ url: '/clasification/get/ecommerce' }),
        get_clasification_id: ({ p_id_clasification }) => Helpers.axios({ url: '/clasification/get/id/' + p_id_clasification }),
        clasification_insert_update: (data = {}) => Helpers.axios({ url: '/clasification/insert/update', method: 'POST', data }),
        clasification_delete: (data = {}) => Helpers.axios({ url: '/clasification/delete', method: 'POST', data })
    },
    product: {
        get_product: ({ p_id_branch_office = '' } = {}) => Helpers.axios({ url: '/product/get/all/' + p_id_branch_office }),
        get_product_type_price: ({ p_id_branch_office = '', p_id_type_price = '' } = {}) => Helpers.axios({ url: '/product/get/typeprice/' + p_id_branch_office + '/' + p_id_type_price }),
        get_product_id: ({ p_id_product }) => Helpers.axios({ url: '/product/get/id/' + p_id_product }),
        product_insert_update: (data = {}) => Helpers.axios({ url: '/product/insert/update', method: 'POST', data }),
        product_delete: (data = {}) => Helpers.axios({ url: '/product/delete', method: 'POST', data }),
        get_product_by_customers: ({ p_id_clasification } = {}) => Helpers.axios({ url: '/product/get/customers/' + p_id_clasification }),
        get_product_validate_stock: (data = {}) => Helpers.axios({ url: '/product/validate/stock', data, method: 'POST' }),
        get_product_lot: ({ p_id_product = '' } = {}) => Helpers.axios({ url: '/product/get/lot/' + p_id_product }),
    },
    product_photo: {
        get_product_photo: ({ p_id_product } = {}) => Helpers.axios({ url: '/productphoto/get/all/' + p_id_product }),
        get_product_photo_id: ({ p_id_photo }) => Helpers.axios({ url: '/productphoto/get/id/' + p_id_photo }),
        product_photo_insert_update: (data = {}) => Helpers.axios({ url: '/productphoto/insert/update', method: 'POST', data, hasFile: true }),
        product_photo_delete: (data = {}) => Helpers.axios({ url: '/productphoto/delete', method: 'POST', data })
    },
    component: {
        get_component: () => Helpers.axios({ url: '/component/get/all' }),
        get_component_id: ({ p_id_component }) => Helpers.axios({ url: '/component/get/id/' + p_id_component }),
        component_insert_update: (data = {}) => Helpers.axios({ url: '/component/insert/update', method: 'POST', data }),
        component_delete: (data = {}) => Helpers.axios({ url: '/component/delete', method: 'POST', data })
    },
    product_component: {
        get_product_component: ({ p_id_product } = {}) => Helpers.axios({ url: '/productcomponent/get/all/' + p_id_product }),
        get_product_component_id: ({ p_id_component, p_id_product }) => Helpers.axios({ url: '/productcomponent/get/id/' + p_id_product + '/' + p_id_component }),
        product_component_insert_update: (data = {}) => Helpers.axios({ url: '/productcomponent/insert/update', method: 'POST', data }),
        product_component_delete: (data = {}) => Helpers.axios({ url: '/productcomponent/delete', method: 'POST', data })
    },
    product_price: {
        get_product_price: ({ p_id_product } = {}) => Helpers.axios({ url: '/productprice/get/all/' + p_id_product }),
        product_price_insert_update: (data = {}) => Helpers.axios({ url: '/productprice/insert/update', method: 'POST', data }),
    },
    type_document: {
        get_type_document: () => Helpers.axios({ url: '/typedocument/get/all' }),
        get_type_document_id: ({ p_id_type_document }) => Helpers.axios({ url: '/typedocument/get/id/' + p_id_type_document }),
        type_document_insert_update: (data = {}) => Helpers.axios({ url: '/typedocument/insert/update', method: 'POST', data }),
        type_document_delete: (data = {}) => Helpers.axios({ url: '/typedocument/delete', method: 'POST', data })
    },
    document: {
        get_document: () => Helpers.axios({ url: '/document/get/all' }),
        get_document_id: ({ p_id_document }) => Helpers.axios({ url: '/document/get/id/' + p_id_document }),
        document_insert_update: (data = {}) => Helpers.axios({ url: '/document/insert/update', method: 'POST', data }),
        document_delete: (data = {}) => Helpers.axios({ url: '/document/delete', method: 'POST', data }),
        get_customers_sale: () => Helpers.axios({ url: '/document/get/customers/sale' }),
        get_customers_find_sale: ({ p_id_customers }) => Helpers.axios({ url: '/document/get/customers/find/sale/' + p_id_customers }),
    },
    inventory: {
        get_inventory: () => Helpers.axios({ url: '/inventory/get/all' }),
        get_inventory_id: ({ p_id_inventory }) => Helpers.axios({ url: '/inventory/get/id/' + p_id_inventory }),
        inventory_insert_update: (data = {}) => Helpers.axios({ url: '/inventory/insert/update', method: 'POST', data }),
        inventory_delete: (data = {}) => Helpers.axios({ url: '/inventory/delete', method: 'POST', data })
    },
    sale: {
        get_sale: () => Helpers.axios({ url: '/sale/get/all' }),
        get_sale_id: ({ p_id_sale }) => Helpers.axios({ url: '/sale/get/id/' + p_id_sale }),
        sale_insert_update: (data = {}) => Helpers.axios({ url: '/sale/insert/update', method: 'POST', data }),
        sale_delete: (data = {}) => Helpers.axios({ url: '/sale/delete', method: 'POST', data }),
        get_sale_total: () => Helpers.axios({ url: '/sale/get/total/' }),
        get_sale_product_qty: () => Helpers.axios({ url: '/sale/get/product/qty' }),
        get_sale_product_price: () => Helpers.axios({ url: '/sale/get/product/price' }),
    },
    period: {
        get_period: () => Helpers.axios({ url: '/period/get/all' }),
        get_period_current: () => Helpers.axios({ url: '/period/get/current' }),
        get_period_id: ({ p_id_period }) => Helpers.axios({ url: '/period/get/id/' + p_id_period }),
        period_insert_update: (data = {}) => Helpers.axios({ url: '/period/insert/update', method: 'POST', data }),
        period_delete: (data = {}) => Helpers.axios({ url: '/period/delete', method: 'POST', data }),
        get_plan_calculate_range: ({ p_id_period }) => Helpers.axios({ url: '/webhook/calculate/range/' + p_id_period }),
    },
    dashboard: {
        get_customers_summary: () => Helpers.axios({ url: '/dashboard/get/customers/summary' }),
        get_customers_last_purchase_two_months: () => Helpers.axios({ url: '/dashboard/get/customers/purchase/two/months' }),
        get_customers_by_nivel: ({ p_nivel, p_up = 0, p_qualifier = 1, p_status = '' } = {}) => Helpers.axios({ url: '/dashboard/get/customers/nivel/' + p_nivel + '/' + p_up + '/' + p_qualifier + '/' + p_status }),
    },
    cart: {
        get_cart_customers: () => Helpers.axios({ url: '/cart/get/customers' }),
        cart_update: (data = {}) => Helpers.axios({ url: '/cart/update', method: 'POST', data }),
        cart_det_insert_update: (data = {}) => Helpers.axios({ url: '/cart/det/insert/update', method: 'POST', data }),
        get_cart_det: () => Helpers.axios({ url: '/cart/get/det' }),
        cart_det_delete: (data = {}) => Helpers.axios({ url: '/cart/det/delete', method: 'POST', data }),
        cart_det_mass_insert_update: (data = {}) => Helpers.axios({ url: '/cart/det/mass/insert/update', method: 'POST', data }),
        paypal_cart: () => Helpers.axios({ url: '/paypal/cart' }),
        mercadopago_cart: () => Helpers.axios({ url: '/mercadopago/cart' }),
    },
    red: {
        get_my_red: () => Helpers.axios({ url: '/red/get' }),
        get_find_red: ({ p_id_customers }) => Helpers.axios({ url: '/red/find/get/' + p_id_customers }),
        get_my_commissions: () => Helpers.axios({ url: '/commissions/get' }),
    },
    worker: {
        get_worker: () => Helpers.axios({ url: '/worker/get/all' }),
        get_worker_id: ({ p_id_worker }) => Helpers.axios({ url: '/worker/get/id/' + p_id_worker }),
        worker_insert_update: (data = {}) => Helpers.axios({ url: '/worker/insert/update', method: 'POST', data }),
        worker_delete: (data = {}) => Helpers.axios({ url: '/worker/delete', method: 'POST', data })
    },
    exchange: {
        get_exchange: () => Helpers.axios({ url: '/exchange/get/all' }),
        get_exchange_id: ({ p_id_exchange }) => Helpers.axios({ url: '/exchange/get/id/' + p_id_exchange }),
        exchange_insert_update: (data = {}) => Helpers.axios({ url: '/exchange/insert/update', method: 'POST', data }),
        exchange_delete: (data = {}) => Helpers.axios({ url: '/exchange/delete', method: 'POST', data })
    },
    promo: {
        get_promo: () => Helpers.axios({ url: '/promo/get/all' }),
        get_promo_id: ({ p_id_promo }) => Helpers.axios({ url: '/promo/get/id/' + p_id_promo }),
        promo_insert_update: (data = {}) => Helpers.axios({ url: '/promo/insert/update', method: 'POST', data }),
        promo_delete: (data = {}) => Helpers.axios({ url: '/promo/delete', method: 'POST', data })
    },
    text: {
        get_text: () => Helpers.axios({ url: '/text/get/all' }),
        get_text_id: ({ p_id_text }) => Helpers.axios({ url: '/text/get/id/' + p_id_text }),
        text_insert_update: (data = {}) => Helpers.axios({ url: '/text/insert/update', method: 'POST', data, hasFile: true }),
        text_delete: (data = {}) => Helpers.axios({ url: '/text/delete', method: 'POST', data })
    },
    file: {
        get_file: () => Helpers.axios({ url: '/file/get/all' }),
        get_file_public: () => Helpers.axios({ url: '/file/get/public' }),
        get_file_id: ({ p_id_file }) => Helpers.axios({ url: '/file/get/id/' + p_id_file }),
        file_insert_update: (data = {}) => Helpers.axios({ url: '/file/insert/update', method: 'POST', data }),
        file_delete: (data = {}) => Helpers.axios({ url: '/file/delete', method: 'POST', data })
    },
    report: {
        get_break_box_worker: (data = {}) => Helpers.axios({ url: '/report/break/box/user', method: 'POST', data }),
        get_break_box_branch_office: (data = {}) => Helpers.axios({ url: '/report/break/box/branch/office', method: 'POST', data }),
        get_report_commissions_period: ({ p_id_period }) => Helpers.axios({ url: '/report/commisions/period/' + p_id_period }),
        get_report_commissions_period_by_customers: () => Helpers.axios({ url: '/report/my/commisions/' }),
        get_report_point_period: ({ p_id_period }) => Helpers.axios({ url: '/report/point/period/' + p_id_period }),
        get_report_point_customers: () => Helpers.axios({ url: '/report/customers/point/' }),
    },
    limit: {
        get_limit: () => Helpers.axios({ url: '/limit/get/all' }),
        get_limit_id: ({ p_id_limit }) => Helpers.axios({ url: '/limit/get/id/' + p_id_limit }),
        limit_insert_update: (data = {}) => Helpers.axios({ url: '/limit/insert/update', method: 'POST', data }),
        limit_delete: (data = {}) => Helpers.axios({ url: '/limit/delete', method: 'POST', data })
    },
    customers_address: {
        get_customers_address: () => Helpers.axios({ url: '/customers_address/get/all/' }),
        get_customers_address_id: ({ p_id_customers_address }) => Helpers.axios({ url: '/customers_address/get/id/' + p_id_customers_address }),
        customers_address_insert_update: (data = {}) => Helpers.axios({ url: '/customers_address/insert/update', method: 'POST', data }),
        customers_address_delete: (data = {}) => Helpers.axios({ url: '/customers_address/delete', method: 'POST', data })
    },
    dispatch: {
        get_dispatch: () => Helpers.axios({ url: '/dispatch/get/all' }),
        get_dispatch_id: ({ p_id_dispatch }) => Helpers.axios({ url: '/dispatch/get/id/' + p_id_dispatch }),
        dispatch_insert_update: (data = {}) => Helpers.axios({ url: '/dispatch/insert/update', method: 'POST', data }),
        dispatch_delete: (data = {}) => Helpers.axios({ url: '/dispatch/delete', method: 'POST', data })
    },
}
export default Controllers;