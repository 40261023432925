import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, TextArea, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ProductList from '../Product/ProductList'
import { toast } from 'react-toastify';

const DocumentForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_document: data.id_document,
        p_number_invoice_doc: data.number_invoice_doc,
        p_number_lot_doc: data.number_lot_doc,
        p_id_period: data.id_period,
        p_code_period: data.code_period,
        p_id_type_document: data.id_type_document,
        p_observation_doc: data.observation_doc,
        p_id_branch_office_origin: data.id_branch_office_origin,
        p_id_branch_office_destination: data.id_branch_office_destination,
        p_date_doc: data.date_doc,
        p_details: data.details,
    })

    const [ready, setReady] = useState(false)
    const [rowsTypeDocument, setRowsTypeDocument] = useState([])
    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [modalProducts, setModalProducts] = useState(false)

    const handleShowModalProducts = () => {
        if (row.p_id_branch_office_origin && !isNaN(parseInt(row.p_id_type_document))) {
            setModalProducts(true)
        } else {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Sucursal y Tipo de Documento', toast })
        }
    }
    const handleHideModalProducts = () => setModalProducts(false)

    useEffect(() => {
        let promises = [
            Controllers.type_document.get_type_document(),
            Controllers.branch_office.get_branch_office(),
            Controllers.period.get_period_current()
        ]

        Promise.all(promises).then(res => {
            setRowsTypeDocument(res[0].data)
            setRowsBranchOffice(res[1].data)
            if (res[2].data?.id_period && data.id_document === '') {
                setRow({ ...row, p_id_period: res[2].data?.id_period, p_code_period: res[2].data?.code_period })
            }
            setReady(true)
        })
    }, [data.id_document])

    const idTypeDocument = useMemo(() => {
        if (!row.p_id_type_document) {
            return
        }

        let finded = rowsTypeDocument.find(t => parseInt(row.p_id_type_document) === parseInt(t.id_type_document))

        if (!finded) {
            return
        }
        return {
            label: finded.name_document,
            value: finded.id_type_document
        }
    }, [row.p_id_type_document, rowsTypeDocument])
    
    const idBranchOfficeOrigin = useMemo(() => {
        if (!row.p_id_branch_office_origin) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office_origin) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office_origin, rowsBranchOffice])

    const idBranchOfficeDestination = useMemo(() => {
        if (!row.p_id_branch_office_destination) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office_destination) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office_destination, rowsBranchOffice])

    const getSubtotal = useCallback(() => {
        let total = 0
        row.p_details.forEach(r => {
            let subt = parseFloat(r.qty) *  parseFloat(r.price)
            if (!isNaN(parseFloat(r.qty) *  parseFloat(r.price))) {
                total += subt
            }
        })

        let iva = total * Helpers.config.percentageIva

        return {
            total: total.toFixed(2),
            subtotal: (total - iva).toFixed(2),
            iva: iva.toFixed(2)
        }
    }, [row.p_details])

    const dataSubmit = useCallback(() => {
        return  {
            ...row,
            p_details: row.p_details.map(r => {
                return {
                    p_id_product: r.id_product,
                    p_key_product: r.key_product,
                    p_name_product: r.name_product,
                    p_lot: r.lot,
                    p_price: r.price,
                    p_qty: r.qty,
                    p_date_expiration: r.date_expiration
                }
            }),
            p_total_doc: getSubtotal().total,
            p_subtotal_doc: getSubtotal().subtotal,
            p_iva_doc: getSubtotal().iva
        }
    }, [row, getSubtotal])

    return <ReactPlaceholder rows={7} ready={ready}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_id_period) {
                Helpers.toast.construct({ response: 'warning', message: 'No hay periodo activo. Comunicarse con el Área responsable.', toast })
                return
            }
            if (!row.p_id_type_document) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione el Tipo de Documento.', toast })
                return
            }
            
            if (!row.p_id_branch_office_origin) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione Sucursal Origen.', toast })
                return
            }

            if (row.p_details.length === 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos.', toast })
                return
            }

            return onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {/* <Input
                text="Folio"
                placeholder="Folio"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.number_invoice_doc}
                onChange={e => setRow({ ...row, p_number_invoice_doc: e.currentTarget.value })}
                maxLength={100}
            /> */}
            {/* <Input
                text="Nro Lote"
                placeholder="Nro Lote"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.number_lot_doc}
                onChange={e => setRow({ ...row, p_number_lot_doc: e.currentTarget.value })}
                maxLength={100}
            /> */}
            <Input
                text="Periodo"
                placeholder="Periodo"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={row.p_code_period}
                disabled
                className="text-center"
            />
            <Input
                text="Fecha"
                placeholder="Fecha"
                type="date"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_doc}
                onChange={e => setRow({ ...row, p_date_doc: e.currentTarget.value })}
            />
            <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text="Tipo Documento"
                options={rowsTypeDocument.map(t => {
                    return {
                        label: t.name_document,
                        value: t.id_type_document
                    }
                })}
                disabled={data.id_document !== ''}
                value={idTypeDocument}
                onChange={e => {
                    if (data.id_document === '') {
                        setRow({ ...row, p_id_type_document: e.value, p_details: [] })
                    }
                }}
            />
            <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text="Sucursal Origen"
                disabled={data.id_document !== ''}
                options={rowsBranchOffice.map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={idBranchOfficeOrigin}
                onChange={e => {
                    if (data.id_document === '') {
                        if (parseInt(e.value) !== parseInt(row.p_id_branch_office_origin)) {
                            setRow({ ...row, p_id_branch_office_origin: e.value, p_details: [] })
                        }
                    }
                }}
            />
            {parseInt(row.p_id_type_document) === 3 ? <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text="Sucursal Destino"
                options={rowsBranchOffice.map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={idBranchOfficeDestination}
                onChange={e => setRow({ ...row, p_id_branch_office_destination: e.value, p_details: [] })}
            /> : ''}
            <TextArea
                text="Comentarios"
                placeholder="Comentarios"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.observation_doc}
                onChange={e => setRow({ ...row, p_observation_doc: e.currentTarget.value })}
                rows="4"
            />
            <div className="mb-2 col-12">
                <Button size="sm" onClick={handleShowModalProducts}>
                    <i className="fa fa-plus mr-1"></i>Agregar Producto
                </Button>
            </div>
            
            <div className="table-responsive col-12">
                <table className="table table-sm table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Clave</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            <th style={{ 
                                minWidth: 200
                             }}>Lote</th>
                            <th>Fecha Caducidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(row.p_details.map(r => {
                            let findLot = r?.lots?.find(l => l.lot === r.lot)

                            return <tr>
                                <td>{r.key_product}</td>
                                <td>{r.name_product}</td>
                                <td>
                                    <Input
                                        className="text-center"
                                        required
                                        type="number"
                                        min="1"
                                        step="1"
                                        {...(parseInt(row.p_id_type_document) === 1 ? {} : {
                                            max: findLot?.exists_current_qty_product
                                        })}
                                        value={!isNaN(parseInt(r.qty)) ? r.qty : ''}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.qty = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        className="text-center"
                                        required
                                        type="number"
                                        min="0.10"
                                        step="0.10"
                                        value={!isNaN(parseInt(r.price)) ? r.price : ''}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.price = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                    />
                                </td>
                                <td>
                                    {parseInt(row.p_id_type_document) === 1 ? <Input
                                        className="text-center"
                                        required
                                        value={r.lot}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.lot = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                    /> : <>
                                        <Select
                                            options={r.lots.map(l => {
                                                return {
                                                    label: l.lot,
                                                    value: l.lot,
                                                    date_expiration: l.date_expiration
                                                }
                                            })}
                                            value={findLot ? {
                                                label: findLot.lot,
                                                value: findLot.lot,
                                                date_expiration: findLot.date_expiration
                                            } : ''}
                                            onChange={e => {
                                                setRow({
                                                    ...row,
                                                    p_details: row.p_details.map(rr => {
                                                        if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                            rr.lot = e.value
                                                            rr.date_expiration = e.date_expiration
                                                        }
                                                        return rr
                                                    })
                                                })
                                            }}
                                        />
                                    </>}
                                </td>
                                <td>
                                    <Input
                                        type="date"
                                        className="text-center"
                                        required
                                        value={parseInt(row.p_id_type_document) === 1 ? r.date_expiration : 
                                            (findLot ? findLot.date_expiration : '')}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.date_expiration = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                        disabled={parseInt(row.p_id_type_document) !== 1}
                                    />
                                </td>
                                <td>
                                    <Button size="xs" variant="danger" onClick={() => {
                                        setRow({ ...row, p_details: row.p_details.filter(rr => parseInt(rr.id_product) !== parseInt(r.id_product)) })
                                    }}>
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        }))}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td align="center">Subtotal</td>
                            <td align="center">{getSubtotal().subtotal}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td align="center">IVA</td>
                            <td align="center">{getSubtotal().iva}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td align="center">Total</td>
                            <td align="center">{getSubtotal().total}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </FormCustom>
        <ProductList showModal={modalProducts} viewPack={false} hideModal={handleHideModalProducts} nameSingle="Producto" namePlural="Productos" separator="el" added={row.p_details} idBranchOffice={row.p_id_branch_office_origin} addItem={({ idProduct, keyProduct, nameProduct }) => {
            let newDet = {
                id_product: idProduct,
                key_product: keyProduct,
                name_product: nameProduct,
                lot: '',
                price: '',
                qty: '',
                date_expiration: ''
            }

            if (parseInt(row.p_id_type_document) === 2 || parseInt(row.p_id_type_document) === 3) {
                Controllers.product.get_product_lot({
                    p_id_product: idProduct
                }).then(res => {
                    if (res.data.length === 0) {
                        Helpers.toast.construct({ response: 'warning', message: 'No hay lote/stock disponible.', toast })
                        return
                    }

                    newDet = {
                        ...newDet,
                        lots: res.data
                    }
                    setRow({ ...row, p_details: [ ...row.p_details, newDet ] })
                })
            } else {
                setRow({ ...row, p_details: [ ...row.p_details, newDet ] })
            }
        }} />
    </ReactPlaceholder>
}

DocumentForm.defaultProps = {
    data: {
        id_document: '',
        number_invoice_doc: '',
        number_lot_doc: '',
        code_period: '',
        id_period: '',
        id_type_document: '',
        observation_doc: '',
        id_branch_office_origin: '',
        id_branch_office_destination: '',
        date_doc: '',
        details: []
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default DocumentForm;