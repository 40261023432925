import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import CustomersForm from './CustomersForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';

const Customers = ({ nameSingle, namePlural, separator, publicCustomers, onAddCustomers, customersAdded }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})
    const [formParams, setFormParams] = useState({})
    const [ready, setReady] = useState(false)

    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [dataFilter, setDataFilter] = useState({
        p_id_customers: '',
        p_id_sponsor: '',
        p_id_upline: '',
        p_name_customers: '',
        p_last_name_customers: '',
        p_curp_customers: '',
        p_id_branch_office: ''
    })

    const branchOffice = useMemo(() => {
        if (!dataFilter.p_id_branch_office) {
            return {
                label: 'Todas',
                value: ''
            }
        }

        let finded = rowsBranchOffice.find(t => parseInt(dataFilter.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [dataFilter.p_id_branch_office, rowsBranchOffice])

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            setRowsBranchOffice([ { name_branch_office: 'Todas', id_branch_office: '' }, ...res.data ])
            setReady(true)
        })
    }, [])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.customers[publicCustomers ? 'get_customers_public' : 'get_customers'](dataFilter).then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload, dataFilter])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.customers.customers_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
                setFormParams({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idCustomers) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idCustomers } = $this.dataset

        let promises = [
            Controllers.customers.get_customers_id({ p_id_customers: idCustomers }),
            Controllers.customers.get_customers_bank({ p_id_customers: idCustomers }),
            Controllers.customers.get_customers_social({ p_id_customers: idCustomers })
        ]
        
        Promise.all(promises).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idCustomers}`)
            setDataUpdated({ data: res[0].data })
            let params = {
                ...formParams,
            }
            if (res[1].data.length > 0) {
                params.bank = res[1].data
            }
            if (res[2].data.length > 0) {
                params.social = res[2].data
            }
            setFormParams(params)
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.customers.customers_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idCustomers, nameCustomers } = e.currentTarget.dataset
        setDataDeleted({
            p_id_customers: idCustomers,
            p_name_customers: nameCustomers
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => {
        let columns = [
            { name: 'id_customers', text: 'ID', align: 'center' },
            { name: 'id_upline', text: 'Upline', align: 'center' },
            { name: 'id_sponsor', text: 'Sponsor', align: 'center' },
            { name: 'name_customers', text: 'Nombres' },
            { name: 'last_name_customers', text: 'Apellido Paterno' },
            { name: 'last_name_mot_customers', text: 'Apellido Materno' },
            { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
            { name: 'email_customers', text: 'Email', align: 'center' },
            { name: 'phone_customers', text: 'Telefono', align: 'center' },
            { name: 'last_name_period_purchase_customers', text: 'Último Periódo de Compra' },
        ]

        if (publicCustomers) {
            columns.unshift({
                name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: r => {
                    return !customersAdded.find(c => parseInt(c.id_customers) === parseInt(r.id_customers)) ? <Button variant="dark" size="xs" data-id-customers={r.id_customers} data-id-branch-office={r.id_branch_office} data-name-branch-office={r.name_branch_office} data-email-customers={r.email_customers} data-point-current-customers={r.point_current_customers} data-full-name-customers={r.name_customers + ' ' + r.last_name_customers + ' ' + r.last_name_mot_customers} data-id-type-price={r.id_type_price} onClick={e => {
                        const { idBranchOffice, nameBranchOffice, emailCustomers, pointCurrentCustomers, fullNameCustomers, idCustomers, idTypePrice } = e.currentTarget.dataset

                        onAddCustomers({ idBranchOffice, nameBranchOffice, emailCustomers, pointCurrentCustomers, fullNameCustomers, idCustomers, idTypePrice })
                    }}>
                        <i className="fa fa-plus"></i>
                    </Button> : <Button size="xs" variant="success">
                        <i className="fa fa-check"></i>
                    </Button>
                }
            })
        } else {
            columns.push({
                name: 'save_online', text: 'Registro En Línea',
                align: 'center',
                render: u => {
                    let saveOnline = parseInt(u.save_online)
                    return <strong className={'text-' + (saveOnline === 0 ? 'dark' : 'success')}>
                        {saveOnline === 0 ? 'NO' : 'SI'}
                    </strong>
                }
            })
            columns.push({
                name: 'pay_membership', text: 'Pago Membresía',
                align: 'center',
                render: u => {
                    if (parseInt(u.save_online) !== 1) {
                        return ''
                    }
                    let payMembership = parseInt(u.pay_membership)
                    return <strong className={'text-' + (payMembership === 0 ? 'danger' : 'success')}>
                        {payMembership === 0 ? 'NO' : 'SI'}
                    </strong>
                }
            })
            columns.push({ name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                if (parseInt(u.id_customers) === 1) {
                    return ''
                }
                return <div>
                    <Button variant="info" className="mr-1" size="xs" data-id-customers={u.id_customers} onClick={handleGet}>
                        <i className="fa fa-edit"></i>
                    </Button>
                    <Button variant="danger" className="mr-1" size="xs" data-id-customers={u.id_customers} data-name-customers={`${u.name_customers} ${u.last_name_customers} ${u.last_name_mot_customers}`} onClick={handleShowModalDelete}>
                        <i className="fa fa-trash"></i>
                    </Button>
                    {u.file_contract ? <a target="_blank" href={Helpers.config.resourcesUrl({ url: u.file_contract })}>
                        <Button variant="danger" size="xs">
                        <i className="fa fa-file-pdf"></i>
                    </Button>
                    </a> : ''}
                </div>
            } })
        }

        return <ReactDataTableBootstrap
            head={[
                columns
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing, customersAdded, publicCustomers])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} rows={15} showLoadingAnimation>
                    {/* <FormCustom viewSubmit={false} className="mb-3">
                        <Input 
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="fas fa-sort-numeric-up"
                            placeholder="ID"
                            text="ID"
                            onChange={e => setDataFilter({ ...dataFilter, p_id_customers: e.currentTarget.value })}
                        />
                        <Input
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="fas fa-sort-numeric-up"
                            placeholder="Upline"
                            text="Upline"
                            onChange={e => setDataFilter({ ...dataFilter, p_id_upline: e.currentTarget.value })}
                        />
                        <Input
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="fas fa-sort-numeric-up"
                            placeholder="Sponsor"
                            text="Sponsor"
                            onChange={e => setDataFilter({ ...dataFilter, p_id_sponsor: e.currentTarget.value })}
                        />
                        <Input
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="far fa-user"
                            placeholder="Nombres"
                            text="Nombres"
                            onChange={e => setDataFilter({ ...dataFilter, p_name_customers: e.currentTarget.value })}
                        />
                        <Input
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="far fa-user"
                            placeholder="Apellidos"
                            text="Apellidos"
                            onChange={e => setDataFilter({ ...dataFilter, p_last_name_customers: e.currentTarget.value })}
                        />
                        <Select
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="far fa-building"
                            text="Sucursal"
                            options={rowsBranchOffice.map(t => {
                                return {
                                    label: t.name_branch_office,
                                    value: t.id_branch_office
                                }
                            })}
                            value={branchOffice}
                            onChange={e => setDataFilter({ ...dataFilter, p_id_branch_office: e.value })}
                        />
                        <Input
                            classNameParent="col-12 col-md-4 mb-2"
                            icon="fas fa-sort-numeric-up"
                            placeholder="CURP" text="CURP"
                            maxLength={18}
                            pattern="[0-9A-Za-z]{18}"
                            invalid="Por favor, ingresar texto alfumérico de 18 caracteres."
                            append={[<Button>
                                <i className="fa fa-search mr-2"></i>Buscar
                            </Button>]}
                            onChange={e => setDataFilter({ ...dataFilter, p_curp_customers: e.currentTarget.value })}
                        />
                    </FormCustom> */}

                    { dtRows }
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomersForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} {...formParams} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_customers} - {dataDeleted.p_name_customers}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

Customers.defaultProps = {
    publicCustomers: false,
    onAddCustomers: () => {},
    customersAdded: []
}

export default Customers