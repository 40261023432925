import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../../Components/Helpers/Helpers'
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap'
import Modal from 'react-bootstrap/Modal'

const Dashboard = _ => {
    const [summary, setSummary] = useState([])
    const [ready, setReady] = useState(false)
    const [rows, setRows] = useState([])

    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        Controllers.dashboard.get_customers_summary().then(res => {
            setSummary(res.data)
            setReady(true)
        })
    }, [])

    const dtRowsDetails = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center' },
                { name: 'full_name_customers', text: 'Nombres', align: 'center' }
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    const loadRowsDetails = e => {
        const { type } = e.currentTarget.dataset

        setRows([])
        setModalShow(true)
        setIsProcessing(true)
        let promises = []
        if (type === 'first') {
            setModalTitle('Descendencia Primer Nivel')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 1
            }))
        } else if (type === 'second') {
            setModalTitle('Descendencia Segundo Nivel')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 2
            }))
        } else if (type === 'third') {
            setModalTitle('Descendencia Tercer Nivel')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 3
            }))
        } else if (type === 'fourth') {
            setModalTitle('Descendencia Cuarto Nivel')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 4
            }))
        } else if (type === 'fifth') {
            setModalTitle('Descendencia Quinto Nivel')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 5
            }))
        } else if (type === 'sixth') {
            setModalTitle('Descendencia Sexto Nivel - Infinito')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 6,
                p_up: 1
            }))
        } else if (type === 'all') {
            setModalTitle('Descendencia Primer Nivel - Infinito')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 1,
                p_up: 1
            }))
        } else if (type === 'notMinPoint') {
            setModalTitle('Consultores que no llegaron a puntos mínimos')
            promises.push(Controllers.dashboard.get_customers_by_nivel({
                p_nivel: 1,
                p_up: 1,
                p_qualifier: 0
            }))
        } else if (type === 'two-months') {
            setModalTitle('Consultores inactivos por más de 2 meses')
            promises.push(Controllers.dashboard.get_customers_last_purchase_two_months())
        }

        if (promises.length) {
            Promise.all(promises).then(res => {
                setRows(res[0].data)
                setIsProcessing(false)
            })
        }
    }

    return <div className="form-row">
        <div className="col-12">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
                <Card className="bg-success mb-4" style={{
                    minWidth: 250
                }}>
                    <Card.Body className="text-white text-center d-flex flex-column height-100">
                        <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                            <span className="mb-2">ID: <strong>{summary.id_customers}</strong></span>
                            <span>Nombre: <strong>{summary.full_name_customers}</strong></span>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
                <Card className="bg-success mb-4 align-self-center" style={{
                    minWidth: 250
                }}>
                    <Card.Body className="text-white text-center">
                        <ReactPlaceholder ready={ready} showLoadingAnimation rows={1}>
                            <span className="mb-2">Periódo: <strong>{summary.name_period}</strong></span>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className={'text-center d-flex flex-column height-100 position-relative' + (parseInt(summary.point_current_customers?.replace(',', '')) >= Helpers.config.pointMinRange ? ' bg-success text-white' : '')}>
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Puntos</span>
                        <strong className="f-size-18">{summary.point_current_customers}</strong>
                        {parseInt(summary.point_current_customers?.replace(',', '')) >= Helpers.config.pointMinRange ? <div className="position-absolute d-flex justify-content-center" style={{ bottom: 10, width: 'calc(100% - 2.50rem)' }}>
                            <strong>¡FELICIDADES! Ya calificaste.</strong>
                        </div> : ''}
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Valor Negocio</span>
                        <strong className="f-size-18">{summary.point_business_customers}</strong>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Rango</span>
                        <div>
                            {summary.icon_plan ? <img src={Helpers.config.resourcesUrl({ url: summary.icon_plan })} style={{
                                maxWidth: 100
                            }} /> : <strong>{summary.name_plan}</strong>}
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 1er Nivel</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_first_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="first" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 2do Nivel</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_second_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="second" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 3er Nivel</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_third_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="third" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 4to Nivel</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_fourth_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="fourth" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 5to Nivel</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_fifth_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="fifth" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Calificados 6to Nivel-Infinito</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qualifier_sixth_nivel}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="sixth" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Puntos Grupales de toda la Red</span>
                        <strong className="f-size-18 align-self-center">{summary.point_group_red}</strong>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Puntos Grupales del 5to Nivel al Infinito</span>
                        <strong className="f-size-18 align-self-center">{summary.qualifier_group_fifth_nivel}</strong>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Personas inactivas por más de 2 meses</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qty_customers_inactive_two_months}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="two-months" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Cantidad de Consultores en tu Red Activos e Inactivos</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qty_customers_active_and_inactive}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="all" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Consultores que han comprado, pero no han alcanzado los 4mil puntos de calificación</span>
                        <div>
                            <strong className="f-size-18 align-self-center">{summary.qty_customers_without_min_points}</strong>
                            <i className="fas fa-info-circle ml-2 text-info cur-pointer align-self-center" data-type="notMinPoint" onClick={loadRowsDetails}></i>
                        </div>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <div className="col-md-4 col-12 col-sm-6 mb-4">
            <Card>
                <Card.Body className="text-center d-flex flex-column height-100">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={2}>
                        <span className="mb-2">Total de Comisiones Cobradas</span>
                        <strong className="f-size-18 align-self-center">{summary.total_earnings}</strong>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
        <Modal show={modalShow} onHide={_ => setModalShow(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dtRowsDetails}
            </Modal.Body>
        </Modal>
    </div>
}

export default Dashboard